export const Types = {
    SCREENING_QUES_ANS_LISTING: "SCREENING_QUES_ANS_LISTING",
    SCREENING_QUES_ANS_LISTING_ERROR: "SCREENING_QUES_ANS_LISTING_ERROR",
    STUDY_IMAGES: "STUDY_IMAGES",
    PHOTO_GALLERY:"PHOTO_GALLERY",
    PATIENT_DATA: "PATIENT_DATA",
    PATIENT_DATA_NOT_FOUND: "PATIENT_DATA_NOT_FOUND",
    GET_APPROVED_PATIENTS:"GET_APPROVED_PATIENTS",
    GET_APPROVED_PATIENTS_JANSSEN:"GET_APPROVED_PATIENTS_JANSSEN",
    PHOTO_GALLERY_BY_WEEK:"PHOTO_GALLERY_BY_WEEK",
    RESET_PHOTO_GALLERY_BY_WEEK: "RESET_PHOTO_GALLERY_BY_WEEK",
    NIH_PHOTO_GALLERY: "NIH_PHOTO_GALLERY",
    EASI_SCORES:"EASI_SCORES",
    PHOTO_GALLERY_BY_WEEK_ERROR:"PHOTO_GALLERY_BY_WEEK_ERROR",
    NIH_PHOTO_GALLERY_ERROR: "NIH_PHOTO_GALLERY_ERROR",
    PATIENT_SELF_ESTIMATION:"PATIENT_SELF_ESTIMATION",
    PATIENT_SELF_ESTIMATION_JANSSEN:"PATIENT_SELF_ESTIMATION_JANSSEN",
    UPDATE_PATIENT_SELF_ESTIMATION:"UPDATE_PATIENT_SELF_ESTIMATION",
    JANSENN_PHOTO_GALLERY_BY_WEEK:"JANSENN_PHOTO_GALLERY_BY_WEEK",
    JANSENN_IMAGES_SCORES:"JANSENN_IMAGES_SCORES",
    JANSSEN_PHOTO_GALLERY_NEW: "JANSSEN_PHOTO_GALLERY_NEW",
    JANSENN_PHOTO_GALLERY_WEEK: "JANSENN_PHOTO_GALLERY_WEEK",
    WEEK_BY_PARTS: "WEEK_BY_PARTS",
    REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
    REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
    BULK_USER_IMPORT: "BULK_USER_IMPORT",
    UPLOAD_PHOTO: "UPLOAD_PHOTO",
  };