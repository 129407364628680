import API from "../api/medication-api";
import { Types } from "../constants/medication-types";


export function listMedications(patientId,studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listMedications(patientId,studyId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_MEDICATIONS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;
    }
  };
}

export function listFlaresMedications(taskTrueId, patientId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listFlaresMedications(taskTrueId, patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_MEDICATIONS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;
    }
  };
}


export function medicationDetails(data) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.medicationDetails(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.MEDICATION_DETAILS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function medicationDetailsNih(data) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.medicationDetailsNih(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.MEDICATION_DETAILS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function newMedicationDetailsNih(data) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.newMedicationDetailsNih(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.MEDICATION_DETAILS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function flaresMedicationReview(data) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.flaresMedicationReview(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.MEDICATION_DETAILS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function listFlares(data) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.listFlares(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.MEDICATION_DETAILS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function getFlareTasks(userId,startDateStr) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getFlareTasks(userId,startDateStr);
      console.log('resp=>',resp);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_MEDICATIONS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function addDoctorNotesOnMedication(data) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.addDoctorNotesOnMedication(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDoctorCommentsOnMedication(eventId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDoctorCommentsOnMedication(eventId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_DOCTOR_NOTES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.DOCTOR_NOTES_NOT_FOUND,
          payload: "Doctor notes not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getMedictionTypes(userId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getMedictionTypes(userId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.MEDICATIONS_TYPES,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;
    }
  };
}

export function newListMedications(studyId,patientId,type) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.newListMedications(studyId,patientId,type);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_MEDICATIONS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;
    }
  };
}